<template>
  <li class="order-table-list__item font" :class="itemClass">
    <div class="order-table-list__item-name">
      {{ item.name }}
    </div>
    <div
      class="order-table-list__item-value"
      :class="{
        'order-table-list__item-red': item.name.toLowerCase().includes('скидка')
      }"
    >
      {{ item.value }}
    </div>
  </li>
</template>

<script>
export default {
  name: 'CheckoutOrderTableListItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    options() {
      return this.item?.options || {}
    },
    itemClass() {
      const fontSize = this.options?.size ? `font_${this.options.size}` : ''
      const fontColor = this.options?.color ? `font_${this.options.color}` : ''

      return {
        'font_bold': this.options?.bold,
        [fontSize]: this.options?.size,
        [fontColor]: this.options?.color,
      }
    },
  },
}
</script>

