<template>
  <CheckoutOrderTable
    :list="orderList"
    :total="totalList"
  >
    <slot />

    <template #total>
      <slot name="total" />
    </template>
  </CheckoutOrderTable>
</template>

<script>
import {mapGetters} from 'vuex';

import CheckoutOrderTable from '~/components/checkout/OrderTable';
import {discountType} from '~/plugins/enums/checkout';
import {discountNames} from '~/plugins/enums/cart';

export default {
  name: 'CartTotalsBody',
  components: {CheckoutOrderTable},
  props: {
    pageType: {
      type: String,
      required: true,
      validator(value) {
        return ['products', 'services'].includes(value)
      },
    },
  },
  data() {
    return {
      discountType,
      discountNames,
      tableType: {
        products: 'Товары',
        services: 'Услуги',
      },
      totalOptions: {
        bold: false,
        size: 'title-s',
        color: '',
      },
      bonusOptions: {
        bold: false,
        size: 'm',
        color: 'green-bright',
      },
    }
  },
  computed: {
    ...mapGetters('currency', { getCurrency: 'getCurrency' }),
    ...mapGetters('cart', {
      price: 'getPrice',
      totalBonuses: 'getTotalBonuses',
      totalPrice: 'getTotalPrice',
      discounts: 'getDiscounts',
    }),
    discountList() {
      const discounts = []

      if (!this.discounts[this.pageType].length) return discounts

      this.discounts[this.pageType].forEach(discount => {
        const type = discount.type
        let name = ''

        if (type === this.discountType.PRODUCT) {
          name = this.discountNames[type][this.pageType]
        } else {
          name = this.discountNames[type]
        }

        discounts.push({ name, value: `- ${this.preparePrice(discount.value.value)}` })
      })

      return discounts
    },
    orderList() {
      const price = this.price[this.pageType]
      const value = price ? this.preparePrice(price) : 'Бесплатно'

      return [
        { name: 'Сумма заказа', value },
        ...this.discountList,
      ]
    },
    totalList() {
      const bonusGet = []

      if (this.totalBonuses[this.pageType]) {
        bonusGet.push({
          name: 'Бонусы за покупку',
          value: `+ ${this.getCurrency(this.totalBonuses[this.pageType], 1, false)}`,
          options: this.bonusOptions,
        })
      }

      const price = this.totalPrice[this.pageType]
      const value = price ? this.preparePrice(price) : 'Бесплатно'

      return [
        { name: 'Сумма к оплате', value, options: this.totalOptions },
        ...bonusGet,
      ]
    },
  },
  methods: {
    preparePrice(price) {
      return `${this.getCurrency(price)}`
    },
  },

}
</script>

