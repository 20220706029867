<template>
  <div class="order-table">
    <slot />
    <p class="order-table__info">
      Информация о заказе
    </p>
    <CheckoutOrderTableList :list="list">
      <slot name="list" />
    </CheckoutOrderTableList>
    <slot name="promo" />
    <CheckoutOrderTableList class="order-table__total" :list="total">
      <slot name="total" />
    </CheckoutOrderTableList>
  </div>
</template>

<script>
import CheckoutOrderTableList from '~/components/checkout/OrderTable/List';

export default {
  name: 'CheckoutOrderTable',
  components: {CheckoutOrderTableList},
  props: {
    list: {
      type: Array,
      required: true,
    },
    total: {
      type: Array,
      required: true,
    },
  },
}
</script>

