<template>
  <ul class="order-table-list mb_m">
    <CheckoutOrderTableListItem
      v-for="(item, index) in list"
      :key="index"
      :item="item"
    />
    <slot />
  </ul>
</template>

<script>
import CheckoutOrderTableListItem from '~/components/checkout/OrderTable/List/Item';
export default {
  name: 'CheckoutOrderTableList',
  components: {CheckoutOrderTableListItem},
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
}
</script>

